var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"designercard"},[_c('div',{staticClass:"designer-image"},[_c('img',{attrs:{"src":_vm.data.image
                    ? _vm.data.image
                    : require('assets/images/default-designer.png'),"alt":_vm.data.name}})]),_c('div',{staticClass:"designercard-detail"},[_c('h1',{staticClass:"designer-name"},[_vm._v(_vm._s(_vm.data.name))]),(_vm.width < 1100)?_c('p',{staticClass:"designer-projects-count-above"},[_vm._v(" Projects: "),_c('span',[_vm._v(_vm._s(_vm.data.num_of_projects))])]):_vm._e(),_c('p',{staticClass:"designer-location"},[_vm._v(_vm._s(_vm.data.location))]),(_vm.data.description.length > 3)?_c('p',{staticClass:"designer-description"},[_vm._v(" "+_vm._s(_vm.data.description.slice(0, 120))+"... "),_c('router-link',{staticClass:"see-more",attrs:{"to":{
                    name: 'DesignerDetailPortfolio',
                    params: { id: _vm.data.id },
                }}},[_vm._v(" see more ")])],1):_vm._e(),_c('div',{staticClass:"designercard-action"},[_c('router-link',{attrs:{"to":{
                    name: 'DesignerDetailPortfolio',
                    params: { id: _vm.data.id },
                }}},[_c('Button',{attrs:{"primary":"","name":"View Profile"}})],1),(_vm.width > 1100)?_c('p',{staticClass:"designer-projects-count"},[_vm._v(" Projects: "),_c('span',[_vm._v(_vm._s(_vm.data.num_of_projects))])]):_vm._e()],1)]),_c('div',{staticClass:"designercard-showcase"},_vm._l((_vm.data.primary_images_thumb),function(image,index){return _c('div',{key:'primary_img_' + index,staticClass:"primary-img"},[_c('img',{attrs:{"src":image,"alt":"Projects"}})])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }