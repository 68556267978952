<template>
    <div class="designercard">
        <div class="designer-image">
            <img
                :src="
                    data.image
                        ? data.image
                        : require('assets/images/default-designer.png')
                "
                :alt="data.name"
            />
        </div>
        <div class="designercard-detail">
            <h1 class="designer-name">{{ data.name }}</h1>
            <p v-if="width < 1100" class="designer-projects-count-above">
                Projects: <span>{{ data.num_of_projects }}</span>
            </p>
            <p class="designer-location">{{ data.location }}</p>
            <p class="designer-description" v-if="data.description.length > 3">
                {{ data.description.slice(0, 120) }}...
                <router-link
                    class="see-more"
                    :to="{
                        name: 'DesignerDetailPortfolio',
                        params: { id: data.id },
                    }"
                >
                    see more
                </router-link>
            </p>
            <div class="designercard-action">
                <router-link
                    :to="{
                        name: 'DesignerDetailPortfolio',
                        params: { id: data.id },
                    }"
                >
                    <Button primary name="View Profile" />
                </router-link>
                <p v-if="width > 1100" class="designer-projects-count">
                    Projects: <span>{{ data.num_of_projects }}</span>
                </p>
            </div>
        </div>
        <div class="designercard-showcase">
            <div
                class="primary-img"
                v-for="(image, index) in data.primary_images_thumb"
                :key="'primary_img_' + index"
            >
                <img :src="image" alt="Projects" />
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'componentsv2/Button'

export default {
    name: 'DesignerCard',
    components: {
        Button,
    },
    props: {
        data: {
            type: Object,
        },
        width: {
            type: Number,
        },
    },
}
</script>
<style lang="scss">
@import 'DesignerCard.scss';
</style>
