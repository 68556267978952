import { getDesignersList } from 'api/designerApi'

const DesignerListPage = {
    state: ()=>({
        designerList:[],
    }),

    mutations:{
        setDesignerList(state, payload){
            state.designerList = payload
        },

    },

    getters:{

    },

    actions:{
        fetchDesignerList({commit}){
            return getDesignersList().then(payload => {
                if (payload.status === 404) {
                    console.log('fetching failed')
                } else {
                    commit('setDesignerList', payload)
                    return payload
                }
            })
        }
    }
}

export default DesignerListPage