<template>
    <div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <div class="designer-listpage">
            <!-- <DisplayBanner
            class="banner"
            img="indoors.jpg"
            heading="Meet our Interior Designers"
            subHeading="Get Your Home Designed by Very Best Interior Designers Handpicked by Us"
        /> -->
            <div class="head-display">
                <div class="display-image">
                    <div class="img"></div>
                    <div class="display-info">
                        <p class="info-head">Meet Our Interior Designers</p>
                        <p class="info-subhead">
                            Get your home designed by our fabulous Interior
                            Designers.
                        </p>
                        <p class="info-subhead">
                            They are great at what they do and will help you
                            shape your dream space.
                        </p>
                    </div>
                </div>
            </div>
            <div class="designerlist-container">
                <div class="designerlist-row">
                    <div
                        v-for="designer in designersList.designer_data"
                        :key="designer.id"
                        class="designerlist-rowlist"
                    >
                        <DesignerCard :width="windowWidth" :data="designer" />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import DesignersListPageModule from 'store/modules/DesignersListPage'
import IsVisible from 'mixins/IsVisible'

// import DisplayBanner from 'componentsv2/DisplayBanner'
import DesignerCard from 'components/DesignerCard'
import Footer from 'componentsv2/Footer'
import Loader from 'componentsv2/Loader'
import loaderHandler from 'mixins/loader'

export default {
    name: 'DesignersListPage',
    components: {
        // DisplayBanner,
        DesignerCard,
        Footer,
        Loader,
    },
    mixins: [RegisterStoreModule, IsVisible, loaderHandler],
    data() {
        return {
            currentPage: 1,
        }
    },
    computed: {
        ...mapState({
            designersList: state => state.DesignersListPageModule.designerList,
            windowWidth: state => state.AppModule.windowWidth,
        }),
    },
    created() {
        document.querySelector(
            "meta[property='og:title']",
        ).content = `Designers`

        document.querySelector(
            "meta[property='og:description']",
        ).content = `Get your home designed by our fabulous Interior Designers.They are great at what they do and will help you shape your dream space.`
        this.registerStoreModule(
            'DesignersListPageModule',
            DesignersListPageModule,
        )
        this.fetchDesignerList().then(res=>{
            setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
        }).catch(err=>{
            setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
        })
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
    destroyed() {
        this.$store.unregisterModule('DesignerListPageModule')
    },
    methods: {
        ...mapActions({
            fetchDesignerList: 'fetchDesignerList',
            onResize: 'onResize',
        }),
    },
}
</script>

<style lang="scss">
@import './DesignersListPage.scss';
</style>
